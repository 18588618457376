import React from 'react';

interface Stats {
  total: number;
  kept: number;
  skipped: number;
  pending: number;
}

interface StatsBarProps {
  stats: Stats;
}

export const StatsBar: React.FC<StatsBarProps> = ({ stats }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mb-4 flex-shrink-0">
      <div className="grid grid-cols-4 gap-4">
        <div className="p-3 bg-gray-50 rounded-lg">
          <div className="text-sm font-medium text-gray-500">Total QA Pairs</div>
          <div className="text-2xl font-semibold text-gray-900">{stats.total}</div>
        </div>
        <div className="p-3 bg-green-50 rounded-lg">
          <div className="text-sm font-medium text-green-600">Kept</div>
          <div className="text-2xl font-semibold text-green-700">{stats.kept}</div>
        </div>
        <div className="p-3 bg-red-50 rounded-lg">
          <div className="text-sm font-medium text-red-600">Skipped</div>
          <div className="text-2xl font-semibold text-red-700">{stats.skipped}</div>
        </div>
        <div className="p-3 bg-yellow-50 rounded-lg">
          <div className="text-sm font-medium text-yellow-600">Pending</div>
          <div className="text-2xl font-semibold text-yellow-700">{stats.pending}</div>
        </div>
      </div>
    </div>
  );
};
