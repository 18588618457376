import React from 'react';

interface BreadcrumbsProps {
  currentIndex: number;
  totalItems: number;
  onNavigate: (index: number) => void;
  onPrevious: () => void;
  onNext: () => void;
  isNextDisabled?: boolean;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  currentIndex,
  totalItems,
  onNavigate,
  onPrevious,
  onNext,
  isNextDisabled
}) => {
  // Calculate which items to show in breadcrumbs
  const getVisibleItems = () => {
    const items = [];
    const maxVisible = 5; // Show max 5 items
    
    if (totalItems <= maxVisible) {
      // If total items is less than max visible, show all
      for (let i = 0; i < totalItems; i++) {
        items.push(i);
      }
    } else {
      // Always show first and last
      items.push(0);
      
      // Show items around current
      const start = Math.max(1, currentIndex - 1);
      const end = Math.min(totalItems - 2, currentIndex + 1);
      
      if (start > 1) items.push(-1); // Add ellipsis
      for (let i = start; i <= end; i++) {
        items.push(i);
      }
      if (end < totalItems - 2) items.push(-1); // Add ellipsis
      
      items.push(totalItems - 1);
    }
    
    return items;
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <button
            onClick={onPrevious}
            disabled={currentIndex === 0}
            className={`p-2 rounded-md ${
              currentIndex === 0
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-700 hover:bg-gray-100'
            }`}
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          
          <div className="flex items-center">
            {getVisibleItems().map((index, i) => (
              <React.Fragment key={i}>
                {index === -1 ? (
                  <span className="px-2 text-gray-400">...</span>
                ) : (
                  <button
                    onClick={() => onNavigate(index)}
                    className={`min-w-[2.5rem] h-10 px-3 mx-1 rounded-md text-sm font-medium transition-colors ${
                      currentIndex === index
                        ? 'bg-blue-500 text-white'
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    {index + 1}
                  </button>
                )}
              </React.Fragment>
            ))}
          </div>

          <button
            onClick={onNext}
            disabled={isNextDisabled || currentIndex === totalItems - 1}
            className={`p-2 rounded-md ${
              isNextDisabled || currentIndex === totalItems - 1
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-700 hover:bg-gray-100'
            }`}
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>

        <div className="text-sm text-gray-500">
          Context {currentIndex + 1} of {totalItems}
        </div>
      </div>
    </div>
  );
};
