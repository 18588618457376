import React from 'react';
import { QAPairItem } from './QAPairItem';
import { AddQAPair } from './AddQAPair';
import { QAPair } from '../../types';

interface QAPairListProps {
  qaPairs: QAPair['qa_pairs'];
  onKeep: (keep: boolean, qaIndex: number) => void;
  onAddQAPair: (question: string, answer: string) => void;
}

export const QAPairList: React.FC<QAPairListProps> = ({ qaPairs, onKeep, onAddQAPair }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm flex flex-col min-h-0">
      <div className="p-4 border-b flex-shrink-0">
        <label className="block text-lg font-semibold text-gray-800">
          <span className="inline-flex items-center">
            <svg className="w-5 h-5 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Question-Answer Pairs
          </span>
        </label>
      </div>
      <div className="flex-1 overflow-auto p-4 min-h-0">
        <AddQAPair onAdd={onAddQAPair} />
        <div className="space-y-4 pb-16">
          {qaPairs.map((qa, qaIndex) => (
            <QAPairItem
              key={qaIndex}
              question={qa.question}
              answer={qa.answer}
              index={qaIndex}
              keep={qa.keep}
              onKeep={(keep) => onKeep(keep, qaIndex)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
