import React from 'react';

interface ActionBarProps {
  onDownload: () => void;
  onNext?: () => void;
  isNextDisabled?: boolean;
}

export const ActionBar: React.FC<ActionBarProps> = ({
  onDownload,
  onNext,
  isNextDisabled
}) => {
  return (
    <div className="fixed bottom-2 left-2 right-2 bg-white p-4 rounded-lg shadow-lg flex justify-between items-center z-10">
      <button
        onClick={onDownload}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-md transition-colors"
      >
        <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
        </svg>
        Download Progress
      </button>
      {onNext && (
        <button
          onClick={onNext}
          disabled={isNextDisabled}
          className={`inline-flex items-center px-6 py-2 rounded-md text-sm font-medium transition-colors ${
            isNextDisabled 
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed' 
              : 'bg-blue-500 text-white hover:bg-blue-600'
          }`}
        >
          Next (→)
          <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      )}
    </div>
  );
};
