import React from 'react';
import { QAPair } from '../types';
import { useKeyboardShortcuts } from '../hooks/useKeyboardShortcuts';
import { StatsBar } from './qa-review/StatsBar';
import { ContextViewer } from './qa-review/ContextViewer';
import { QAPairList } from './qa-review/QAPairList';
import { ActionBar } from './qa-review/ActionBar';
import { Breadcrumbs } from './qa-review/Breadcrumbs';
import { calculateStats, handleDownloadProgress } from './qa-review/utils';

interface QAPairReviewProps {
  data: QAPair;
  allData: QAPair[];
  currentIndex: number;
  onKeep: (keep: boolean, qaIndex: number) => void;
  onNext?: () => void;
  onPrevious?: () => void;
  onNavigate: (index: number) => void;
  isNextDisabled?: boolean;
  onUpdateData: (updatedData: QAPair) => void;
}

export const QAPairReview: React.FC<QAPairReviewProps> = ({ 
  data, 
  allData,
  currentIndex,
  onKeep, 
  onNext, 
  onPrevious,
  onNavigate,
  isNextDisabled,
  onUpdateData
}) => {
  // Calculate statistics for current context
  const stats = calculateStats(data);

  // Get the current QA pair that needs a decision
  const currentQAIndex = data.qa_pairs.findIndex(qa => qa.keep === undefined);

  // Setup keyboard shortcuts
  useKeyboardShortcuts({
    onKeep: () => {
      if (currentQAIndex !== -1) {
        onKeep(true, currentQAIndex);
      }
    },
    onSkip: () => {
      if (currentQAIndex !== -1) {
        onKeep(false, currentQAIndex);
      }
    },
    onNext: onNext || (() => {}),
    isNextDisabled: isNextDisabled || false,
  });

  const handleAddQAPair = (question: string, answer: string) => {
    // Create a new QA pair
    const newQAPair = {
      question,
      answer,
      keep: true
    };

    // Create a new data object with the updated qa_pairs array
    const updatedData = {
      ...data,
      qa_pairs: [...data.qa_pairs, newQAPair]
    };

    // Update the parent component with the new data
    onUpdateData(updatedData);
  };

  return (
    <div className="h-[calc(100vh-2rem)] flex flex-col relative">
      <Breadcrumbs
        currentIndex={currentIndex}
        totalItems={allData.length}
        onNavigate={onNavigate}
        onPrevious={onPrevious || (() => {})}
        onNext={onNext || (() => {})}
        isNextDisabled={isNextDisabled}
      />
      
      <StatsBar stats={stats} />
      
      <div className="flex-1 grid grid-cols-2 gap-4 min-h-0">
        <ContextViewer context={data.context} />
        <QAPairList 
          qaPairs={data.qa_pairs} 
          onKeep={onKeep}
          onAddQAPair={handleAddQAPair}
        />
      </div>

      <ActionBar 
        onDownload={() => handleDownloadProgress(allData, currentIndex)}
        onNext={onNext}
        isNextDisabled={isNextDisabled}
      />
    </div>
  );
};
