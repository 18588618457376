import React from 'react';

interface QAPairItemProps {
  question: string;
  answer: string;
  index: number;
  keep: boolean | undefined;
  onKeep: (keep: boolean) => void;
}

export const QAPairItem: React.FC<QAPairItemProps> = ({ 
  question, 
  answer, 
  index, 
  keep, 
  onKeep 
}) => {
  return (
    <div 
      className={`bg-white p-4 rounded-lg shadow-sm border-l-4 transition-colors ${
        keep === undefined ? 'border-gray-200' :
        keep === true ? 'border-green-500' : 'border-red-500'
      }`}
    >
      <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          <span className="inline-flex items-center">
            <svg className="w-4 h-4 mr-2 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Question {index + 1}
          </span>
        </label>
        <div className="p-3 bg-gray-50 rounded-lg whitespace-pre-wrap text-gray-700 border border-gray-100">
          {question}
        </div>
      </div>
      <div className="mb-3">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          <span className="inline-flex items-center">
            <svg className="w-4 h-4 mr-2 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            Answer {index + 1}
          </span>
        </label>
        <div className="p-3 bg-gray-50 rounded-lg whitespace-pre-wrap text-gray-700 border border-gray-100">
          {answer}
        </div>
      </div>
      <div className="flex justify-end space-x-3">
        <button
          onClick={() => onKeep(false)}
          className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium transition-colors ${
            keep === false 
              ? 'bg-red-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
        >
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
          Skip (X)
        </button>
        <button
          onClick={() => onKeep(true)}
          className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium transition-colors ${
            keep === true 
              ? 'bg-green-500 text-white' 
              : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
          }`}
        >
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
          Keep (✓)
        </button>
      </div>
    </div>
  );
};
