import React from 'react';
import { QAPair } from '../types';

interface DownloadButtonProps {
  data: QAPair[];
}

export const DownloadButton: React.FC<DownloadButtonProps> = ({ data }) => {
  const handleDownload = () => {
    // Filter the data to only include kept QA pairs
    const filteredData = data
      .map(item => ({
        context: item.context,
        qa_pairs: item.qa_pairs.filter(qa => qa.keep === true)
      }))
      .filter(item => item.qa_pairs.length > 0); // Remove contexts with no kept QA pairs

    const jsonString = JSON.stringify(filteredData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'filtered_data.json');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="space-y-4 text-center">
      <h2 className="text-2xl font-semibold text-gray-900">All Done!</h2>
      <p className="text-gray-600">You've reviewed all entries.</p>
      <button
        onClick={handleDownload}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
      >
        Download Filtered Data
      </button>
    </div>
  );
};
