import React, { useState, useCallback } from 'react';
import { FileUpload } from './components/FileUpload';
import { QAPairReview } from './components/QAPairReview';
import { DownloadButton } from './components/DownloadButton';
import { StartOptions } from './components/StartOptions';
import { QAPair } from './types';

function App() {
  const [data, setData] = useState<QAPair[]>([] as QAPair[]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [hasStarted, setHasStarted] = useState<boolean>(false);

  const handleDataLoad = useCallback((newData: QAPair[]) => {
    setData(newData);
    setCurrentIndex(0);
    setIsDone(false);
    setHasStarted(false);
  }, []);

  const handleStart = useCallback((startIndex: number) => {
    setCurrentIndex(startIndex);
    setHasStarted(true);
  }, []);

  const handleKeep = useCallback((keep: boolean, qaIndex: number) => {
    setData(prev => {
      const newData = [...prev];
      // Create a deep copy of the current item to ensure state updates properly
      const currentItem = {
        ...newData[currentIndex],
        qa_pairs: [...newData[currentIndex].qa_pairs]
      };
      
      // Toggle the keep state if it's already set to the same value
      const currentQA = currentItem.qa_pairs[qaIndex];
      currentItem.qa_pairs[qaIndex] = {
        ...currentQA,
        keep: currentQA.keep === keep ? undefined : keep
      };
      
      newData[currentIndex] = currentItem;
      return newData;
    });
  }, [currentIndex]);

  const handleUpdateData = useCallback((updatedData: QAPair) => {
    setData(prev => {
      const newData = [...prev];
      newData[currentIndex] = updatedData;
      return newData;
    });
  }, [currentIndex]);

  const handleNext = useCallback(() => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(prev => prev + 1);
    } else {
      setIsDone(true);
    }
  }, [currentIndex, data.length]);

  const handlePrevious = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  }, [currentIndex]);

  const handleNavigate = useCallback((index: number) => {
    if (index >= 0 && index < data.length) {
      setCurrentIndex(index);
    }
  }, [data.length]);

  const isAllMarked = data[currentIndex]?.qa_pairs.every(qa => qa.keep !== undefined) ?? false;

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="max-w-5xl mx-auto">
        <div className="mb-6">
          <h1 className="text-3xl font-bold text-gray-900">
            QA Pair Review Tool
          </h1>
        </div>

        <div className="space-y-6">
          {isDone ? (
            <div className="space-y-4">
              <div className="bg-green-50 p-4 rounded-lg">
                <p className="text-green-800">All contexts have been reviewed!</p>
              </div>
              <div>
                <DownloadButton data={data} />
              </div>
            </div>
          ) : data.length === 0 ? (
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <FileUpload
                onDataLoad={handleDataLoad}
              />
            </div>
          ) : !hasStarted ? (
            <StartOptions
              totalItems={data.length}
              onStart={handleStart}
            />
          ) : (
            <div className="space-y-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-semibold text-gray-900">
                  Context {currentIndex + 1} of {data.length}
                </h2>
              </div>
              <QAPairReview
                data={data[currentIndex]}
                allData={data}
                currentIndex={currentIndex}
                onKeep={handleKeep}
                onNext={handleNext}
                onPrevious={handlePrevious}
                onNavigate={handleNavigate}
                isNextDisabled={!data[currentIndex].qa_pairs.every(qa => qa.keep !== undefined)}
                onUpdateData={handleUpdateData}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
