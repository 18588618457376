import React, { useState } from 'react';

interface StartOptionsProps {
  totalItems: number;
  onStart: (startIndex: number) => void;
}

export const StartOptions: React.FC<StartOptionsProps> = ({ totalItems, onStart }) => {
  const [startIndex, setStartIndex] = useState(0);

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm space-y-6">
      <h2 className="text-2xl font-semibold text-gray-900">Choose where to start</h2>
      
      <div className="space-y-4">
        <button
          onClick={() => onStart(0)}
          className="w-full px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
        >
          Start from beginning
        </button>
        
        <div className="space-y-2">
          <div className="flex justify-between text-sm text-gray-600">
            <span>Or choose a custom starting point:</span>
            <span>Context {startIndex + 1} of {totalItems}</span>
          </div>
          
          <input
            type="range"
            min={0}
            max={Math.max(0, totalItems - 1)}
            value={startIndex}
            onChange={(e) => setStartIndex(parseInt(e.target.value))}
            className="w-full"
          />
          
          <button
            onClick={() => onStart(startIndex)}
            className="w-full px-4 py-2 bg-gray-100 text-gray-800 rounded-md hover:bg-gray-200 transition-colors"
          >
            Start from Context {startIndex + 1}
          </button>
        </div>
      </div>
    </div>
  );
};
