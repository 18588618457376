import { QAPair } from '../../types';

export const calculateStats = (data: QAPair) => {
  return {
    total: data.qa_pairs.length,
    kept: data.qa_pairs.filter(qa => qa.keep === true).length,
    skipped: data.qa_pairs.filter(qa => qa.keep === false).length,
    pending: data.qa_pairs.filter(qa => qa.keep === undefined).length
  };
};

export const handleDownloadProgress = (allData: QAPair[], currentIndex: number) => {
  // Filter the data to only include kept QA pairs
  const filteredData = allData
    .map(item => ({
      context: item.context,
      qa_pairs: item.qa_pairs.filter(qa => qa.keep === true)
    }))
    .filter(item => item.qa_pairs.length > 0);

  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
  const totalContexts = allData.length;
  const keptPairs = filteredData.reduce((sum, item) => sum + item.qa_pairs.length, 0);
  const fileName = `context_${currentIndex + 1}_of_${totalContexts}_kept_${keptPairs}_pairs_${timestamp}.json`;

  const jsonString = JSON.stringify(filteredData, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};
