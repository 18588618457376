import { useEffect } from 'react';

interface UseKeyboardShortcutsProps {
  onKeep: () => void;
  onSkip: () => void;
  onNext: () => void;
  isNextDisabled: boolean;
}

export const useKeyboardShortcuts = ({
  onKeep,
  onSkip,
  onNext,
  isNextDisabled,
}: UseKeyboardShortcutsProps) => {
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Ignore key events when focus is in an input field
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        return;
      }

      switch (event.key.toLowerCase()) {
        case 'k':
        case 'y':
          onKeep();
          break;
        case 'x':
        case 'n':
          onSkip();
          break;
        case 'arrowright':
          if (!isNextDisabled) {
            onNext();
          }
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [onKeep, onSkip, onNext, isNextDisabled]);
};
