import React, { useState, useRef } from 'react';
import { QAPair } from '../types';

interface FileUploadProps {
  onDataLoad: (data: QAPair[]) => void;
  currentData?: QAPair[];
}

export const FileUpload: React.FC<FileUploadProps> = ({ onDataLoad, currentData }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const validateFile = (file: File): boolean => {
    if (!file.type.includes('json') && !file.name.endsWith('.json')) {
      setError('Please upload a JSON file');
      return false;
    }

    if (file.size > 500 * 1024 * 1024) {
      setError('File size should be less than 500MB');
      return false;
    }

    return true;
  };

  const processFile = (file: File) => {
    if (!validateFile(file)) return;

    setFileName(file.name);
    setError(null);
    setIsLoading(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const jsonData = JSON.parse(e.target?.result as string);
        const transformedData = jsonData.map((row: any) => {
          try {
            let qa_pairs = row.qa_pairs || [];
            if (typeof qa_pairs === 'string') {
              qa_pairs = qa_pairs.replace(/'/g, '"').replace(/\\"/g, '"');
              qa_pairs = JSON.parse(qa_pairs);
            }

            return {
              context: row.context || '',
              qa_pairs: qa_pairs.map((qa: any) => ({
                question: qa.question?.trim() || '',
                answer: qa.answer?.trim() || '',
                keep: undefined
              })),
            } as QAPair;
          } catch (error) {
            console.error('Error parsing row:', row);
            return null;
          }
        }).filter(isQAPair);

        onDataLoad(transformedData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error parsing JSON file:', error);
        setError('Invalid JSON format. Please check your file and try again.');
        setIsLoading(false);
      }
    };

    reader.onerror = () => {
      setError('Error reading file. Please try again.');
      setIsLoading(false);
    };

    reader.readAsText(file);
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      processFile(file);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      processFile(file);
    }
  };

  const isQAPair = (item: any): item is QAPair => item !== null;

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="space-y-4">
      <div
        className={`relative border-2 border-dashed rounded-lg p-8 text-center ${
          isDragging
            ? 'border-blue-500 bg-blue-50'
            : 'border-gray-300 hover:border-gray-400'
        }`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          accept=".json"
          className="hidden"
        />
        
        <div className="space-y-2">
          <div className="flex justify-center">
            <svg
              className="w-12 h-12 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              />
            </svg>
          </div>
          <div className="text-gray-600">
            {isLoading ? (
              <p>Processing file...</p>
            ) : (
              <>
                <p className="text-sm">
                  Drag and drop your JSON file here, or{' '}
                  <span className="text-blue-500 hover:text-blue-600 cursor-pointer">
                    browse
                  </span>
                </p>
                <p className="text-xs text-gray-500 mt-1">JSON files only, up to 500MB</p>
              </>
            )}
          </div>
        </div>
      </div>

      {error && (
        <div className="text-red-500 text-sm text-center">{error}</div>
      )}
    </div>
  );
};
