import React from 'react';

interface ContextViewerProps {
  context: string;
}

export const ContextViewer: React.FC<ContextViewerProps> = ({ context }) => {
  return (
    <div className="bg-white rounded-lg shadow-sm flex flex-col min-h-0">
      <div className="p-4 border-b flex-shrink-0">
        <label className="block text-lg font-semibold text-gray-800">
          <span className="inline-flex items-center">
            <svg className="w-5 h-5 mr-2 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            Context
          </span>
        </label>
      </div>
      <div className="flex-1 overflow-auto p-4 min-h-0">
        <div className="h-full p-4 bg-gray-50 rounded-lg whitespace-pre-wrap text-gray-700 leading-relaxed border border-gray-100">
          {context}
        </div>
      </div>
    </div>
  );
};
